





















































































import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import NoteCreateDialog from "@/components/dialogs/NoteCreateDialog.vue";
import NoteUpdateDialog from "@/components/dialogs/NoteUpdateDialog.vue";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import {
ContractType,
  CrewMemberNoteEvidenceModel,
  CrewMemberNoteModel
} from "@/api/generated";
import FileDownloadUtility from "@/utility/fileDownloadUtility";
import UserModule from "@/store/userModule";

const userModule = getModule(UserModule);
const snackbarModule = getModule(SnackbarModule);

@Component({ components: { NoteCreateDialog, NoteUpdateDialog } })
export default class CrewMemberNotes extends Vue {
  @Prop(String) private crewMemberId!: string;
  private crewMemberNoteId = "";
  private updateDialog = false;
  private createDialog = false;
  private loading = false;
  private items: Array<CrewMemberNoteModel> = [];
  private noteEvidence: Array<CrewMemberNoteEvidenceModel> = [];

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  private async created() {
    await this.getCrewMemberNotes();
  }

  private async getCrewMemberNotes() {
    try {
      this.loading = true;
      if (this.agencyAdminRole) {
        const response = await Api.CrewMemberNoteService.apiCrewmembernoteCrewMemberNonAdminViewCrewMemberIdDiscriminatorGet(
          this.crewMemberId,
          ContractType.NUMBER_1
        );
        this.items = response.data;
      } else {
        const response = await Api.CrewMemberNoteService.apiCrewmembernoteCrewMemberCrewMemberIdDiscriminatorGet(
          this.crewMemberId,
          ContractType.NUMBER_1
        );
        this.items = response.data;
      }
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch crew member notes");
    } finally {
      this.loading = false;
    }
  }

  private onCreateNote() {
    this.createDialog = true;
  }

  private async onDeleteNote(item: CrewMemberNoteModel) {
    if (!item.crewMemberNoteId) {
      return;
    }

    try {
      this.loading = true;
      await Api.CrewMemberNoteService.apiCrewmembernoteCrewMemberNoteIdDelete(
        item.crewMemberNoteId
      );
      snackbarModule.setSnackbarMessage("Note deleted");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to delete note");
    } finally {
      this.loading = false;
    }

    await this.getCrewMemberNotes();
  }

  private onUpdateNote(item: CrewMemberNoteModel) {
    if (!item.crewMemberNoteId) {
      return;
    }

    this.crewMemberNoteId = item.crewMemberNoteId;
    this.updateDialog = true;
  }

  private async onDownloadEvidence(evidence: CrewMemberNoteEvidenceModel) {
    if (!evidence.documentId) {
      return;
    }

    const fileResponse = await Api.DocumentService.apiDocumentDocumentIdGet(
      evidence.documentId
    );
    if (evidence.fileName != null) {
      FileDownloadUtility.DownloadFile(fileResponse.data, evidence.fileName);
    }
  }
}
